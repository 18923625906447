import { ComponentProps, JSX } from "react";
import classNames from "classnames";

type IconProps = ComponentProps<"i"> & {
  className?: string;
  iconText?: string;
};

const Icon = ({ className, iconText, ...restProps }: IconProps): JSX.Element | null => {
  if (!className) {
    return null;
  }

  return (
    <i {...restProps} className={classNames("cp-resume-icon", className)} aria-label={iconText} title={iconText} />
  );
};

export default Icon;
