import { ComponentProps, JSX } from "react";
import { isNil, stripProtocol } from "@/utils";
import DateRange from "@/components/resume/dates/DateRange";
import HighlightsList from "@/components/resume/lists/highlights/HighlightsList";
import Link from "@/components/resume/links/Link";
import LocationLink from "@/components/resume/locations/LocationLink";
import PipeItem from "@/components/resume/lists/pipes/PipeItem";
import Pipes from "@/components/resume/lists/pipes/Pipes";
import { VolunteerItemType } from "@/types/resume";
import classNames from "classnames";
import { useResumeConfig } from "@/context/resume";

type VolunteerItemProps = ComponentProps<"li"> & {
  volunteerItem: VolunteerItemType;
};

const VolunteerItem = ({ volunteerItem, className, ...restProps }: VolunteerItemProps): JSX.Element | null => {
  const { iconSet, mapApi, months, pipes } = useResumeConfig();

  if (isNil(volunteerItem) || volunteerItem.hide) {
    return null;
  }

  return (
    <li {...restProps} className={classNames("cp-resume-volunteer-item", className)}>
      <Pipes className="cp-resume-volunteer-info" blockOnMobile={true}>
        <PipeItem itemValue={volunteerItem.position} itemClassName="cp-resume-volunteer-position" itemType={pipes} />
        <PipeItem
          itemValue={
            <DateRange
              startDate={volunteerItem.startDate}
              endDate={volunteerItem.endDate}
              months={months}
              className="cp-resume-volunteer-dates"
            />
          }
          itemClassName="cp-resume-volunteer-dates"
          itemType={pipes}
        />
        <PipeItem
          itemValue={volunteerItem.organization}
          itemClassName="cp-resume-volunteer-company-organization"
          itemType={pipes}
        />
        <PipeItem
          itemValue={
            <LocationLink
              iconSet={iconSet}
              locationQuery={volunteerItem.location}
              mapApi={mapApi}
              iconPlacement={"end"}
            />
          }
          itemClassName="cp-resume-volunteer-company-location"
          itemType={pipes}
        />
        {volunteerItem.url && (
          <PipeItem
            itemValue={<Link href={volunteerItem.url} linkText={stripProtocol(volunteerItem.url)} target="_blank" />}
            itemClassName="cp-resume-volunteer-url"
            itemType={pipes}
          />
        )}
      </Pipes>
      <HighlightsList highlights={volunteerItem.highlights} />
    </li>
  );
};

export default VolunteerItem;
