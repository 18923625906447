import "@/components/resume/lists/interests/Interests.scss";
import { ComponentProps, JSX } from "react";
import { createKeyFrom, isArray, isNilOrEmpty } from "@/utils";

import InterestItem from "@/components/resume/lists/interests/InterestItem";
import { InterestsType } from "@/types/resume";
import Section from "@/components/resume/sections/Section";
import { checkKeyViewable } from "@/utils";
import { useResumeConfig } from "@/context/resume";

type InterestsProps = ComponentProps<"section"> & {
  interests?: InterestsType;
  header?: string;
};

const Interests = ({ interests = [], header = "Interests:", ...restProps }: InterestsProps): JSX.Element | null => {
  const { titles, viewable } = useResumeConfig();

  if (
    !checkKeyViewable("interests", viewable) ||
    isNilOrEmpty(interests) ||
    (isArray(interests) && interests.length < 1)
  ) {
    return null;
  }
  const heading = titles?.interests ?? header;

  return (
    <Section {...restProps} className="cp-resume-interests" heading={heading}>
      <ul className="cp-resume-interests-list">
        {interests.map((interest) => {
          return interest.hide ? null : <InterestItem key={createKeyFrom(interest.name)} interestItem={interest} />;
        })}
      </ul>
    </Section>
  );
};

export default Interests;
