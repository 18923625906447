import "@/components/resume/sections/Summary.scss";

import { ComponentProps, JSX } from "react";
import Section from "@/components/resume/sections/Section";
import { SummaryType } from "@/types/resume";
import { isNilOrEmpty } from "@/utils";
import { useResumeConfig } from "@/context/resume";

type SummaryProps = ComponentProps<"section"> & {
  summaryText?: SummaryType;
  header?: string;
};

const Summary = ({ summaryText, header = "Summary:", ...restProps }: SummaryProps): JSX.Element | null => {
  const { titles } = useResumeConfig();

  if (isNilOrEmpty(summaryText)) {
    return null;
  }

  const heading = titles?.summary ?? header;

  return (
    <Section {...restProps} className="cp-resume-summary" heading={heading}>
      <p className="cp-resume-summary-text">{summaryText}</p>
    </Section>
  );
};

export default Summary;
