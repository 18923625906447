import "@/components/resume/lists/custom/CustomSections.scss";

import { ComponentProps, JSX } from "react";
import { checkKeyViewable, isArray, isNilOrEmpty } from "@/utils";

import CustomSection from "@/components/resume/lists/custom/CustomSection";
import { ResumeCustomSectionListType } from "@/types/resume";
import { useResumeConfig } from "@/context/resume";

type CustomSectionsProps = ComponentProps<"div"> & {
  customSections?: ResumeCustomSectionListType;
};

const CustomSections = ({ customSections, ...restProps }: CustomSectionsProps): JSX.Element | null => {
  const { viewable } = useResumeConfig();

  if (
    !checkKeyViewable("custom", viewable) ||
    isNilOrEmpty(customSections) ||
    !isArray(customSections) ||
    (isArray(customSections) && !(customSections.length > 0))
  ) {
    return null;
  }

  return (
    <div {...restProps} className="cp-resume-custom-sections">
      {customSections?.map((customSection, i) => {
        return <CustomSection customSection={customSection} key={`${customSection.title.replace(" ", "_")}-${i}`} />;
      })}
    </div>
  );
};

export default CustomSections;
