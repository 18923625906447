import "@/components/resume/lists/projects/Projects.scss";
import { ComponentProps, JSX } from "react";
import { checkKeyViewable, createKeyFrom, isArray, isNilOrEmpty } from "@/utils";

import ProjectItem from "@/components/resume/lists/projects/ProjectItem";
import { ProjectsType } from "@/types/resume";
import Section from "@/components/resume/sections/Section";
import { useResumeConfig } from "@/context/resume";

type ProjectsProps = ComponentProps<"section"> & {
  projects?: ProjectsType;
  header?: string;
};

const Projects = ({ projects = [], header = "Projects:", ...restProps }: ProjectsProps): JSX.Element | null => {
  const { titles, viewable } = useResumeConfig();

  if (!checkKeyViewable("projects", viewable) || isNilOrEmpty(projects) || (isArray(projects) && projects.length < 1)) {
    return null;
  }
  const heading = titles?.projects ?? header;

  return (
    <Section {...restProps} className="cp-resume-projects" heading={heading}>
      <ul className="cp-resume-projects-list">
        {projects.map((project) => {
          return project.hide ? null : <ProjectItem key={createKeyFrom(project.name)} projectItem={project} />;
        })}
      </ul>
    </Section>
  );
};

export default Projects;
