import { ComponentProps, JSX } from "react";

import { AwardItemType } from "@/types/resume";
import DateTime from "@/components/resume/dates/DateTime";
import PipeItem from "@/components/resume/lists/pipes/PipeItem";
import Pipes from "@/components/resume/lists/pipes/Pipes";
import classNames from "classnames";
import { isNilOrEmpty } from "@/utils";
import { useResumeConfig } from "@/context/resume";

type AwardItemProps = ComponentProps<"li"> & {
  awardItem: AwardItemType;
};

const AwardItem = ({ awardItem, className, ...restProps }: AwardItemProps): JSX.Element | null => {
  const { months, pipes } = useResumeConfig();

  if (isNilOrEmpty(awardItem) || awardItem.hide) {
    return null;
  }

  return (
    <li {...restProps} className={classNames("cp-resume-award-item", className)}>
      <Pipes className="cp-resume-award-info" blockOnMobile={true}>
        <PipeItem itemValue={awardItem.title} itemClassName="cp-resume-award-title" itemType={pipes} />
        <PipeItem
          itemValue={<DateTime date={awardItem.date} months={months} noDate={"No Date"} />}
          itemClassName="cp-resume-award-date"
          itemType={pipes}
        />
        <PipeItem itemValue={awardItem.awarder} itemClassName="cp-resume-award-awarder" itemType={pipes} />
        <PipeItem itemValue={awardItem.summary} itemClassName="cp-resume-award-summary" itemType={pipes} />
      </Pipes>
    </li>
  );
};

export default AwardItem;
