import "@/components/form/ResumeSelect.scss";
import { ChangeEventHandler, ComponentProps, JSX } from "react";
import BaseSelect from "@/components/form/BaseSelect";
import { DefaultSelectResumeOption } from "@/consts";
import { ResumeSelectOption } from "@/types/base";
import { buildResumeSelectOptions } from "@/utils";
import classNames from "classnames";

type ResumeSelectProps = ComponentProps<"select"> & {
  hideSelect?: boolean;
  hideResumeOption?: boolean;
  hideFullResumeOption?: boolean;
  hideDemoOptions?: boolean;
  onResumeSelect?: (value: ResumeSelectOption) => void;
  defaultValue?: ResumeSelectOption;
  className?: string;
};

const ResumeSelect = ({
  hideSelect = true,
  hideResumeOption = true,
  hideFullResumeOption = true,
  hideDemoOptions = true,
  onResumeSelect,
  defaultValue = DefaultSelectResumeOption,
  className,
  name = "resume-selector",
  title = "Which resume to view?",
  ...restProps
}: ResumeSelectProps): JSX.Element | null => {
  if (hideSelect) {
    return null;
  }

  const resumeSelectOptions = buildResumeSelectOptions({
    noOptions: hideSelect,
    hideResumeOption,
    hideFullResumeOption,
    hideDemoOptions,
  });

  const onResumeSelectChange: ChangeEventHandler<HTMLSelectElement> = (e) => {
    const selected = e.target.value as ResumeSelectOption;
    return onResumeSelect && onResumeSelect(selected);
  };

  if (resumeSelectOptions.length < 2) {
    return null;
  }

  return (
    <BaseSelect
      {...restProps}
      name={name}
      className={classNames("cp-resume-select", className)}
      defaultValue={defaultValue}
      onChange={onResumeSelectChange}
      title={title}
      aria-label={restProps["aria-label"] ?? title}
    >
      {resumeSelectOptions.map((option) => {
        return (
          <option value={option.value} key={`${name}-option-${option.value}`}>
            {option.text}
          </option>
        );
      })}
    </BaseSelect>
  );
};

export default ResumeSelect;
