import { ComponentProps, JSX } from "react";
import { checkKeyViewable, createKeyFrom, isArray, isNilOrEmpty } from "@/utils";

import Pipes from "@/components/resume/lists/pipes/Pipes";
import ProfileItem from "@/components/resume/lists/profiles/ProfileItem";
import { ProfileType } from "@/types/resume";
import classNames from "classnames";
import { useResumeConfig } from "@/context/resume";

type ProfilesProps = ComponentProps<"ul"> & {
  profiles?: ProfileType[];
  header?: string;
};

const Profiles = ({ profiles = [], className, ...restProps }: ProfilesProps): JSX.Element | null => {
  const { viewable } = useResumeConfig();

  if (
    !checkKeyViewable("basics.profiles", viewable) ||
    isNilOrEmpty(profiles) ||
    (isArray(profiles) && profiles.length < 1)
  ) {
    return null;
  }

  return (
    <Pipes {...restProps} className={classNames("cp-resume-profiles", className)}>
      {profiles?.map((profile) => {
        if (profile.hide) {
          return null;
        }

        return <ProfileItem key={createKeyFrom(profile.network)} profileItem={profile} />;
      })}
    </Pipes>
  );
};

export default Profiles;
