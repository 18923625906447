import "@/components/resume/lists/highlights/HighlightItem.scss";
import { ComponentProps, JSX } from "react";

import { HighlightType } from "@/types/resume";
import { isNilOrEmpty } from "@/utils";

type WorkHighlightItemProps = ComponentProps<"li"> & {
  highlight?: HighlightType;
};

const HighlightItem = ({ highlight, ...restProps }: WorkHighlightItemProps): JSX.Element | null => {
  if (isNilOrEmpty(highlight)) {
    return null;
  }

  return (
    <li {...restProps} className="cp-resume-highlight-item">
      {highlight}
    </li>
  );
};

export default HighlightItem;
