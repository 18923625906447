import "@/components/ResumeApp.scss";

import {
  DefaultIconSet,
  DefaultResumePDFUrl,
  DefaultResumeUrl,
  DefaultResumeWordDocUrl,
  DefaultSelectResumeOption,
  EnvConfig,
} from "@/consts";
import { JSX, useState } from "react";
import { ResumeConfigType, ResumeUrl } from "@/types/resume";
import { ResumeSelectOption, ToolbarConfigType } from "@/types/base";
import {
  getResumeConfigFromDefault,
  getResumeTitle,
  getResumeUrlFromHref,
  useDemoConfig,
  useFetchResume,
  useTheme,
  useToolbarConfig,
} from "@/utils";

import CPResume from "@/components/resume/CPResume";
import CPResumeComponent from "@/components/resume/CPResumeComponent";
import ThemeSelect from "@/components/form/ThemeSelect";
import Toolbar from "@/components/toolbar/Toolbar";
import classNames from "classnames";
import resumeBriefConfigJson from "@/data/resumeBriefConfig.json";
import resumeFullConfigJson from "@/data/resumeFullConfig.json";

const ResumeBriefConfigDefaults = getResumeConfigFromDefault(resumeBriefConfigJson as ResumeConfigType);
const ResumeFullConfigDefaults = getResumeConfigFromDefault(resumeFullConfigJson as ResumeConfigType);

type ResumeAppProps = {
  className?: string;
  ["data-testid"]?: string;
  resumeBriefConfig?: ResumeConfigType;
  resumeFullConfig?: ResumeConfigType;
  defaultResumeSelectOption?: ResumeSelectOption;
  toolbarConfig?: Omit<ToolbarConfigType, "iconSet" | "resumeData">;
  demo?: boolean;
};

const ResumeApp = ({
  className,
  resumeBriefConfig = ResumeBriefConfigDefaults,
  resumeFullConfig = ResumeFullConfigDefaults,
  defaultResumeSelectOption = DefaultSelectResumeOption,
  toolbarConfig,
  demo = EnvConfig.demoMode,
  ...restProps
}: ResumeAppProps): JSX.Element => {
  const [selectedResume, setSelectedResume] = useState<ResumeSelectOption>(defaultResumeSelectOption);

  const { demoConfig, isDemo, resumeDemoData } = useDemoConfig({ demo, selectedResume });

  const resumeConfig =
    isDemo && demoConfig ? demoConfig : selectedResume === "res-brief-config" ? resumeBriefConfig : resumeFullConfig;

  //FIXME: Add hash change event to fetch updated resumeUrlToLoad
  const resumeUrl: ResumeUrl = resumeConfig?.resumeUrl ?? DefaultResumeUrl;
  const resumeUrlToLoad = getResumeUrlFromHref({ resumeUrl });
  const resumePDFUrl: ResumeUrl = resumeConfig?.resumePDFUrl ?? DefaultResumePDFUrl;
  const resumeWordDocUrl: ResumeUrl = resumeConfig?.resumeWordDocUrl ?? DefaultResumeWordDocUrl;

  //FIXME: Fetch resume configs? before fetching resumeData
  const { resumeData, isFetching, isComplete, isFailed } = useFetchResume({
    resumeUrl: resumeUrlToLoad,
    isGated: !resumeUrlToLoad,
  });

  const activeResumeData = isDemo ? resumeDemoData : resumeData;
  const currentIconSet = Object.assign({}, DefaultIconSet, resumeConfig.iconSet);
  const resumeFileName = getResumeTitle({
    jobLabel: activeResumeData?.basics?.label,
    fullName: activeResumeData?.basics?.name,
    resumeTitleEndText: resumeConfig?.resumeTitleEndText,
  });

  const { theme, onChangeTheme, isDarkMode, isForceDarkMode, onChangeDarkMode } = useTheme({
    resumeConfig: resumeConfig,
    applyBodyClass: true,
  });

  const hasResume = !!activeResumeData;

  const onResumeSelect = (value: ResumeSelectOption) => setSelectedResume(value);

  const { toolbarProps } = useToolbarConfig({
    defaultConfig: toolbarConfig,
    resumeData: activeResumeData,
    iconSet: currentIconSet,
    isDarkMode,
    isForceDarkMode,
    resumePDFUrl,
    resumeWordDocUrl,
    hide: EnvConfig.hideToolbar || toolbarConfig?.hide || !hasResume,
    hideDemoOptions: toolbarConfig?.hideDemoOptions || !demo || !resumeDemoData,
    resumeFileName,
  });

  return (
    <div
      className={classNames("cp-resume-app", className, {
        "cp-resume-fetching": isFetching,
        "cp-resume-fetched": isComplete,
        "cp-resume-with-toolbar": !toolbarProps.hide,
      })}
      data-testid={restProps["data-testid"]}
    >
      <div className="cp-resume-top" id={`resume`}>
        <span className="sr-only">Top of page</span>
      </div>
      {isFailed && <div className="cp-resume-app-error">FAILED to load {resumeUrlToLoad}</div>}
      {demo && hasResume && (
        <div className="cp-resume-active-theme no-print display-none-mobile">
          <ThemeSelect hideSelect={!demo} theme={theme} onThemeSelect={onChangeTheme} />
        </div>
      )}
      {hasResume && <CPResume resumeData={activeResumeData} resumeConfig={resumeConfig} themeOverride={theme} />}

      {demo && hasResume && theme === "base" && (
        <div className="cp-resume-demo">
          <CPResumeComponent
            resumeData={activeResumeData}
            className="no-print"
            resumeConfig={resumeConfig}
            themeOverride={theme}
          />
        </div>
      )}

      {hasResume && (
        <Toolbar
          data-testid={`${restProps["data-testid"] ?? "resume"}-toolbar`}
          onResumeSelect={onResumeSelect}
          onDarkModeSelect={onChangeDarkMode}
          {...toolbarProps}
        />
      )}
    </div>
  );
};

export default ResumeApp;
