import "@/components/resume/lists/skills/Skills.scss";
import { ComponentProps, JSX } from "react";
import { checkKeyViewable, createKeyFrom, isArray, isNilOrEmpty } from "@/utils";

import Section from "@/components/resume/sections/Section";
import SkillItem from "@/components/resume/lists/skills/SkillItem";
import { SkillsType } from "@/types/resume";
import { useResumeConfig } from "@/context/resume";

type SkillsProps = ComponentProps<"section"> & {
  skills?: SkillsType;
  header?: string;
  asTable?: boolean;
};

const Skills = ({ skills = [], header = "Skills:", asTable = true, ...restProps }: SkillsProps): JSX.Element | null => {
  const { titles, viewable = [] } = useResumeConfig();

  if (!checkKeyViewable("skills", viewable) || isNilOrEmpty(skills) || !isArray(skills) || skills.length < 1) {
    return null;
  }
  const heading = titles?.skills ?? header;

  return (
    <Section {...restProps} className="cp-resume-skills" heading={heading}>
      {asTable ? (
        <div className="cp-resume-skills-list-table-responsive">
          <table className="cp-resume-skills-list cp-resume-skills-list-as-table">
            <thead className="cp-resume-skills-list-thead sr-only">
              <tr>
                <th>Skill</th>
                <th>Level</th>
              </tr>
            </thead>
            <tbody>
              {skills.map((skill) => {
                return skill.hide ? null : (
                  <tr className="cp-resume-skills-list-row" key={createKeyFrom(skill.name)}>
                    <SkillItem skill={skill} asTable={asTable} />
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      ) : (
        <ul className="cp-resume-skills-list cp-resume-skills-list-as-list">
          {skills.map((skill) => {
            return skill.hide ? null : <SkillItem key={createKeyFrom(skill.name)} skill={skill} asTable={asTable} />;
          })}
        </ul>
      )}
    </Section>
  );
};

export default Skills;
