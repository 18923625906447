import { ComponentProps, JSX } from "react";
import { isNilOrEmpty, stripProtocol } from "@/utils";

import { CertificateItemType } from "@/types/resume";
import DateTime from "@/components/resume/dates/DateTime";
import Link from "@/components/resume/links/Link";
import PipeItem from "@/components/resume/lists/pipes/PipeItem";
import Pipes from "@/components/resume/lists/pipes/Pipes";
import classNames from "classnames";
import { useResumeConfig } from "@/context/resume";

type CertificateItemProps = ComponentProps<"li"> & {
  certificateItem: CertificateItemType;
};

const CertificateItem = ({ certificateItem, className, ...restProps }: CertificateItemProps): JSX.Element | null => {
  const { months, pipes } = useResumeConfig();

  if (isNilOrEmpty(certificateItem) || certificateItem.hide) {
    return null;
  }

  return (
    <li {...restProps} className={classNames("cp-resume-certificate-item", className)}>
      <Pipes className="cp-resume-certificate-info" blockOnMobile={true}>
        <PipeItem itemValue={certificateItem.name} itemClassName="cp-resume-certificate-name" itemType={pipes} />
        <PipeItem
          itemValue={<DateTime date={certificateItem.date} months={months} noDate={"No Date"} />}
          itemClassName="cp-resume-certificate-date"
          itemType={pipes}
        />
        <PipeItem itemValue={certificateItem.issuer} itemClassName="cp-resume-certificate-issuer" itemType={pipes} />
        {certificateItem.url && (
          <PipeItem
            itemValue={
              <Link href={certificateItem.url} linkText={stripProtocol(certificateItem.url)} target="_blank" />
            }
            itemClassName="cp-resume-certificate-url"
            itemType={pipes}
          />
        )}
      </Pipes>
    </li>
  );
};

export default CertificateItem;
