import "@/components/toolbar/Toolbar.scss";

import { FocusEventHandler, useRef, useState } from "react";
import { ResumeSelectOption, ToolbarConfigType } from "@/types/base";

import DarkModeSelect from "@/components/form/DarkModeSelect";
import JSONDownloadLink from "@/components/resume/links/JSONDownloadLink";
import JSONPretty from "react-json-pretty";
import ResumeSelect from "@/components/form/ResumeSelect";
import { ResumeThemeType } from "@/types/resume";
import ToolbarBuiltWith from "@/components/toolbar/ToolbarBuiltWith";
import ToolbarOptionJsonToggleBtn from "@/components/toolbar/options/ToolbarOptionJsonToggleBtn";
import ToolbarOptionPDFLink from "@/components/toolbar/options/ToolbarOptionPDFLink";
import ToolbarOptionPrintBtn from "@/components/toolbar/options/ToolbarOptionPrintBtn";
import ToolbarOptionWordDocLink from "@/components/toolbar/options/ToolbarOptionWordDocLink";
import classNames from "classnames";
import { clipElement } from "@/utils";

type ToolbarProps = ToolbarConfigType & {
  className?: string;
  ["data-testid"]?: string;
  onResumeSelect?: (whichResume: ResumeSelectOption) => void;
  onDarkModeSelect?: (whichTheme: ResumeThemeType) => void;
};

const Toolbar = ({
  className,
  resumeData,
  resumePDFUrl,
  resumeWordDocUrl,
  resumeFileName = "resume",
  iconSet,
  hide = false,
  hideDemoOptions = false,
  hideFullResumeOption = false,
  hideResumeOption = false,
  hideResumeSelect = false,
  hideDarkModeSelect = false,
  hideJson = false,
  hidePDF = false,
  hideWordDoc = false,
  hidePrint = false,
  hideBuiltWith = false,
  jsonCopy = false,
  defaultJsonVisible = false,
  isDarkMode = false,
  isForceDarkMode = false,
  onResumeSelect,
  onDarkModeSelect,
  ...restProps
}: ToolbarProps) => {
  const [jsonVisible, setJsonVisible] = useState(defaultJsonVisible ?? false);
  const clipRef = useRef<HTMLDivElement>(null);

  if (hide || !resumeData || (hideBuiltWith && hidePDF && hideWordDoc && hidePrint && hideJson)) {
    return null;
  }

  const onJsonFocus: FocusEventHandler = () => {
    return clipRef?.current && clipElement(clipRef?.current);
  };

  const onToggleJsonClick = () => {
    !jsonVisible && clipRef?.current?.focus();
    setJsonVisible(!jsonVisible);
  };

  return (
    <div
      className={classNames("cp-resume-toolbar", className, {
        "cp-resume-toolbar-json-visible": jsonVisible,
        "cp-resume-toolbar-json-hidden": !jsonVisible,
      })}
      data-testid={restProps["data-testid"]}
    >
      <footer className="cp-resume-toolbar-footer">
        <div
          className="cp-resume-toolbar-json outline"
          ref={clipRef}
          tabIndex={-1}
          onFocus={jsonCopy ? onJsonFocus : undefined}
          aria-label={`A resume for ${resumeData?.basics?.name ?? "no one"} in JSON format.`}
        >
          <JSONPretty id="cp-resume-toolbar-json-pretty" json={resumeData} />

          <div className="cp-resume-toolbar-options">
            <JSONDownloadLink
              fileName="resume"
              json={resumeData}
              iconClassName={`cp-resume-toolbar-option-icon ${iconSet.download}`}
              isTextColor={false}
              className="cp-resume-toolbar-option cp-resume-json-download"
            />
          </div>
        </div>
        <nav className="cp-resume-toolbar-footer-nav">
          {!hideBuiltWith && <ToolbarBuiltWith />}
          <div className="cp-resume-toolbar-options">
            <ResumeSelect
              hideSelect={hideResumeSelect}
              hideResumeOption={hideResumeOption}
              hideFullResumeOption={hideFullResumeOption}
              hideDemoOptions={hideDemoOptions}
              onResumeSelect={onResumeSelect}
              className="cp-resume-toolbar-option"
            />
            <DarkModeSelect
              hideSelect={hideDarkModeSelect}
              onDarkModeSelect={onDarkModeSelect}
              className="cp-resume-toolbar-option display-none-mobile-sm"
              value={isDarkMode ? (isForceDarkMode ? "force-dark" : "supports-dark") : ""}
            />
            {(!hidePDF || !resumePDFUrl) && (
              <ToolbarOptionPDFLink iconSet={iconSet} resumePDFUrl={resumePDFUrl} download={resumeFileName} />
            )}
            {(!hideWordDoc || !resumeWordDocUrl) && (
              <ToolbarOptionWordDocLink
                iconSet={iconSet}
                resumeWordDocUrl={resumeWordDocUrl}
                download={resumeFileName}
              />
            )}

            {!hidePrint && <ToolbarOptionPrintBtn iconSet={iconSet} />}
            {!hideJson && (
              <ToolbarOptionJsonToggleBtn jsonVisible={jsonVisible} onClick={onToggleJsonClick} iconSet={iconSet} />
            )}
          </div>
        </nav>
      </footer>
    </div>
  );
};

export default Toolbar;
