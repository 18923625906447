import "@/components/resume/lists/references/References.scss";
import { ComponentProps, JSX } from "react";
import { checkKeyViewable, createKeyFrom, isArray, isNilOrEmpty } from "@/utils";

import ReferenceItem from "@/components/resume/lists/references/ReferenceItem";
import { ReferencesType } from "@/types/resume";
import Section from "@/components/resume/sections/Section";
import { useResumeConfig } from "@/context/resume";

type ReferencesProps = ComponentProps<"section"> & {
  references?: ReferencesType;
  header?: string;
};

const References = ({ references = [], header = "References:", ...restProps }: ReferencesProps): JSX.Element | null => {
  const { titles, viewable } = useResumeConfig();

  if (
    !checkKeyViewable("references", viewable) ||
    isNilOrEmpty(references) ||
    (isArray(references) && references.length < 1)
  ) {
    return null;
  }
  const heading = titles?.references ?? header;

  return (
    <Section {...restProps} className="cp-resume-references" heading={heading}>
      <ul className="cp-resume-references-list">
        {references.map((reference) => {
          return reference.hide ? null : (
            <ReferenceItem key={createKeyFrom(reference.name)} referenceItem={reference} />
          );
        })}
      </ul>
    </Section>
  );
};

export default References;
