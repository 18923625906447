import { IconSetType, ResumeWordDocUrl } from "@/types/resume";
import { ComponentProps } from "react";
import classNames from "classnames";
import { isNilOrEmpty } from "@/utils";

type ToolbarOptionWordDocLinkProps = ComponentProps<"a"> & {
  iconSet: IconSetType;
  resumeWordDocUrl?: ResumeWordDocUrl;
  srOnlyText?: string;
};

const ToolbarOptionWordDocLink = ({
  iconSet,
  resumeWordDocUrl,
  rel = "noopener noreferrer",
  title = "Click to view Resume Word Document",
  srOnlyText = "View Word Document",
  target = "_blank",
  className,
  ...restProps
}: ToolbarOptionWordDocLinkProps) => {
  if (isNilOrEmpty(resumeWordDocUrl)) {
    return null;
  }

  return (
    <a
      {...restProps}
      href={resumeWordDocUrl}
      title={title}
      target={target}
      rel={rel}
      className={classNames("cp-resume-toolbar-option cp-resume-word", className)}
    >
      <i className={classNames("cp-resume-toolbar-option-icon", iconSet.fileWord)} />
      <span className="sr-only">{srOnlyText}</span>
    </a>
  );
};

export default ToolbarOptionWordDocLink;
