import "@/components/resume/lists/certificates/Certificates.scss";
import { ComponentProps, JSX } from "react";
import { checkKeyViewable, createKeyFrom, isArray, isNilOrEmpty } from "@/utils";

import CertificateItem from "@/components/resume/lists/certificates/CertificateItem";
import { CertificatesType } from "@/types/resume";
import Section from "@/components/resume/sections/Section";
import { useResumeConfig } from "@/context/resume";

type CertificatesProps = ComponentProps<"section"> & {
  certificates?: CertificatesType;
  header?: string;
};

const Certificates = ({
  certificates = [],
  header = "Certificates:",
  ...restProps
}: CertificatesProps): JSX.Element | null => {
  const { titles, viewable } = useResumeConfig();

  if (
    !checkKeyViewable("certificates", viewable) ||
    isNilOrEmpty(certificates) ||
    (isArray(certificates) && certificates.length < 1)
  ) {
    return null;
  }
  const heading = titles?.certificates ?? header;

  return (
    <Section {...restProps} className="cp-resume-certificates" heading={heading}>
      <ul className="cp-resume-certificates-list">
        {certificates.map((certificate) => {
          return certificate.hide ? null : (
            <CertificateItem key={createKeyFrom(certificate.name)} certificateItem={certificate} />
          );
        })}
      </ul>
    </Section>
  );
};

export default Certificates;
