import { ComponentProps } from "react";
import { IconSetType } from "@/types/resume";
import classNames from "classnames";

type ToolbarOptionPrintBtnProps = ComponentProps<"button"> & {
  iconSet: IconSetType;
  srOnlyText?: string;
  onClick?: () => void;
};

const ToolbarOptionPrintBtn = ({
  iconSet,
  type = "button",
  title = "Click to print Resume",
  srOnlyText = "Print Resume",
  onClick = () => window.print(),
  className,
  ...restProps
}: ToolbarOptionPrintBtnProps) => {
  return (
    <button
      {...restProps}
      type={type}
      onClick={onClick}
      title={title}
      className={classNames("cp-resume-toolbar-option cp-resume-print-btn", className)}
    >
      <i className={classNames("cp-resume-toolbar-option-icon", iconSet.print)} />
      <span className="sr-only">{srOnlyText}</span>
    </button>
  );
};

export default ToolbarOptionPrintBtn;
