import "@/components/resume/lists/highlights/HighlightsList.scss";

import { ComponentProps, JSX } from "react";
import { isArray, isEmpty } from "@/utils";

import HighlightItem from "@/components/resume/lists/highlights/HighlightItem";
import { HighlightsType } from "@/types/resume";

type WorkHighlightsListProps = ComponentProps<"ul"> & {
  highlights?: HighlightsType;
};

const HighlightsList = ({ highlights, ...restProps }: WorkHighlightsListProps): JSX.Element | null => {
  if (isEmpty(highlights) || !isArray(highlights) || highlights.length < 1) {
    return null;
  }

  return (
    <ul {...restProps} className="cp-resume-highlights-list" title="Highlights">
      {highlights.map((highlight, index) => {
        return <HighlightItem key={index} highlight={highlight} />;
      })}
    </ul>
  );
};

export default HighlightsList;
