import { ComponentProps, JSX } from "react";
import { LanguageItemType } from "@/types/resume";
import PipeItem from "@/components/resume/lists/pipes/PipeItem";
import Pipes from "@/components/resume/lists/pipes/Pipes";
import classNames from "classnames";
import { isNilOrEmpty } from "@/utils";
import { useResumeConfig } from "@/context/resume";

type LanguageItemProps = ComponentProps<"li"> & {
  languageItem: LanguageItemType;
};

const LanguageItem = ({ languageItem, className, ...restProps }: LanguageItemProps): JSX.Element | null => {
  const { pipes } = useResumeConfig();

  if (isNilOrEmpty(languageItem) || languageItem.hide) {
    return null;
  }

  return (
    <li {...restProps} className={classNames("cp-resume-language-item", className)}>
      <Pipes className="cp-resume-language-info" blockOnMobile={true}>
        <PipeItem itemValue={languageItem.language} itemClassName="cp-resume-language-name" itemType={pipes} />
        <PipeItem itemValue={languageItem.fluency} itemClassName="cp-resume-language-fluency" itemType={pipes} />
      </Pipes>
    </li>
  );
};

export default LanguageItem;
