import "@/components/resume/lists/pipes/Pipes.scss";

import { ComponentProps, JSX, ReactNode } from "react";
import classNames from "classnames";
import { nodesEmpty } from "@/utils";

type PipeItemsProps = ComponentProps<"ul"> & {
  children?: ReactNode;
  className?: string;
  blockOnMobile?: boolean;
};

const Pipes = ({ children, className, blockOnMobile = false, ...restProps }: PipeItemsProps): JSX.Element | null => {
  if (nodesEmpty(children)) {
    return null;
  }

  return (
    <ul
      {...restProps}
      className={classNames("cp-resume-pipes", className, {
        "cp-resume-pipes-block-on-mobile": blockOnMobile,
      })}
    >
      {children}
    </ul>
  );
};

export default Pipes;
