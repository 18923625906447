import { ComponentProps } from "react";
import { IconSetType } from "@/types/resume";
import classNames from "classnames";

type ToolbarOptionJsonToggleBtnProps = ComponentProps<"button"> & {
  jsonVisible?: boolean;
  onClick?: () => void;
  iconSet: IconSetType;
  srOnlyToggleOffText?: string;
  srOnlyToggleOnText?: string;
};

const ToolbarOptionJsonToggleBtn = ({
  jsonVisible = false,
  onClick,
  iconSet,
  srOnlyToggleOffText = "Click to hide Resume JSON",
  srOnlyToggleOnText = "Click to view Resume JSON",
  className,
  ...restProps
}: ToolbarOptionJsonToggleBtnProps) => {
  const toggleMessaging = jsonVisible ? srOnlyToggleOffText : srOnlyToggleOnText;

  return (
    <button
      {...restProps}
      className={classNames("cp-resume-toolbar-option cp-resume-toolbar-json-toggle-btn", className)}
      title={toggleMessaging}
      onClick={onClick}
    >
      <i className={`${jsonVisible ? iconSet.anglesDown : iconSet.anglesUp}`} />
      <span className="sr-only">{toggleMessaging}</span>
    </button>
  );
};

export default ToolbarOptionJsonToggleBtn;
