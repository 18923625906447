import { ComponentProps, JSX } from "react";
import { checkKeyViewable, isNilOrEmpty, stripProtocol } from "@/utils";

import IconLink from "@/components/resume/links/IconLink";
import PipeItem from "@/components/resume/lists/pipes/PipeItem";
import { ProfileType } from "@/types/resume";
import classNames from "classnames";
import { useResumeConfig } from "@/context/resume";

type ProfileItemProps = ComponentProps<"li"> & {
  profileItem: ProfileType;
};

const ProfileItem = ({ profileItem, className, ...restProps }: ProfileItemProps): JSX.Element | null => {
  const { iconSet, pipes, viewable } = useResumeConfig();
  const showProfile = checkKeyViewable("basics.profiles", viewable);

  if (!showProfile || isNilOrEmpty(profileItem) || profileItem.hide) {
    return null;
  }

  const network = `${profileItem.network.toLowerCase()}`;
  const linkTitle = [profileItem.username, stripProtocol(profileItem.url)].join(" ");

  return (
    <PipeItem
      {...restProps}
      itemValue={
        <IconLink
          href={profileItem.url}
          iconText={linkTitle}
          target="_blank"
          className="cp-resume-basics-profile-link"
          iconClassName={classNames({
            [`${iconSet.facebook}`]: network === "facebook",
            [`${iconSet.linkedin}`]: network === "linkedin",
            [`${iconSet.xTwitter}`]: network === "xTwitter" || network.includes("twitter"),
            [`${iconSet.github}`]: network === "github",
            [`${iconSet.instagram}`]: network === "instagram",
          })}
          title={`Network: ${network}`}
        >
          {profileItem.username && (
            <span className="cp-resume-basics-profile-username print-only">{profileItem.username}</span>
          )}
        </IconLink>
      }
      itemClassName={classNames("cp-resume-profile-item", className)}
      itemType={pipes}
    />
  );
};

export default ProfileItem;
