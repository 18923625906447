import "@/components/resume/lists/pipes/PipeItem.scss";
import { ComponentProps, JSX, ReactNode } from "react";
import { isEmpty, isNil, isString } from "@/utils";

import { ResumePipeItemType } from "@/types/resume";
import classNames from "classnames";

type PipeItemProps = ComponentProps<"li"> & {
  itemValue?: string | ReactNode | null | undefined;
  itemClassName?: string;
  itemType?: ResumePipeItemType;
};

const PipeItem = ({
  itemValue,
  itemClassName,
  itemType = "bullet",
  ...restProps
}: PipeItemProps): JSX.Element | null => {
  if (isNil(itemValue) || (isString(itemValue) && isEmpty(itemValue))) {
    return null;
  }

  return (
    <li
      {...restProps}
      className={classNames("cp-resume-pipe-item", itemClassName, {
        [`cp-resume-pipe-item-${itemType}`]: !!itemType,
      })}
    >
      {itemValue}
    </li>
  );
};

export default PipeItem;
