import "@/components/form/BaseSelect.scss";
import { ComponentProps, JSX, ReactNode } from "react";
import classNames from "classnames";
import { nodesEmpty } from "@/utils";

type BaseSelectProps = ComponentProps<"select"> & {
  hideSelect?: boolean;
  children?: ReactNode;
  ["data-testid"]?: string;
};

const BaseSelect = ({ className, children, ...restProps }: BaseSelectProps): JSX.Element | null => {
  if (nodesEmpty(children)) {
    return null;
  }

  return (
    <select {...restProps} className={classNames("cp-resume-base-select", className)}>
      {children}
    </select>
  );
};

export default BaseSelect;
