import "@/components/resume/lists/volunteer/Volunteer.scss";
import { ComponentProps, JSX } from "react";
import { checkKeyViewable, createKeyFrom, isArray, isNil } from "@/utils";

import Section from "@/components/resume/sections/Section";
import VolunteerItem from "@/components/resume/lists/volunteer/VolunteerItem";
import { VolunteerType } from "@/types/resume";
import { useResumeConfig } from "@/context/resume";

type VolunteerProps = ComponentProps<"section"> & {
  volunteer?: VolunteerType;
  header?: string;
};

const Volunteer = ({ volunteer = [], header = "Volunteer:", ...restProps }: VolunteerProps): JSX.Element | null => {
  const { titles, viewable } = useResumeConfig();

  if (
    !checkKeyViewable("volunteer", viewable) ||
    !volunteer ||
    isNil(volunteer) ||
    (isArray(volunteer) && volunteer.length < 1)
  ) {
    return null;
  }
  const heading = titles?.volunteer ?? header;

  return (
    <Section {...restProps} className="cp-resume-volunteer" heading={heading}>
      <ul className="cp-resume-volunteer-list">
        {volunteer.map((volunteerItem) => {
          return volunteerItem.hide ? null : (
            <VolunteerItem key={createKeyFrom(volunteerItem.organization)} volunteerItem={volunteerItem} />
          );
        })}
      </ul>
    </Section>
  );
};

export default Volunteer;
