import { ComponentProps, JSX } from "react";
import { convertDate, isNilOrEmpty } from "@/utils";

import { DateMonthStringsSet } from "@/consts";
import { DateTextType } from "@/types/resume";
import classNames from "classnames";

type DateTimeProps = ComponentProps<"time"> &
  DateTextType & {
    noDate?: string;
  };

const DateTime = ({
  date,
  noDate = "Not Started",
  className,
  months = DateMonthStringsSet,
}: DateTimeProps): JSX.Element | string => {
  if (isNilOrEmpty(date)) {
    return noDate;
  }

  const dateConverted = convertDate({ date, noDate, months });

  return <time className={classNames("cp-date", className)}>{dateConverted}</time>;
};

export default DateTime;
