import "@/components/resume/sections/Section.scss";

import { ComponentProps, JSX, ReactNode } from "react";
import classNames from "classnames";
import { nodesEmpty } from "@/utils";

type SectionProps = ComponentProps<"section"> & {
  hide?: boolean;
  className?: string;
  heading?: string;
  headingLevel?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
  headingHidden?: boolean;
  headingClassName?: string;
  children?: ReactNode;
};

const Section = ({
  heading,
  headingLevel = "h2",
  headingClassName,
  headingHidden,
  children,
  className,
  ...restProps
}: SectionProps): JSX.Element | null => {
  if (nodesEmpty(children)) {
    return null;
  }

  const SectionHead = headingLevel ?? "h2";

  return (
    <section {...restProps} className={classNames("cp-resume-section", className)}>
      {heading && (
        <SectionHead
          className={classNames("cp-resume-section-title", headingClassName, {
            "sr-only": headingHidden,
          })}
        >
          {heading}
        </SectionHead>
      )}
      {children}
    </section>
  );
};

export default Section;
