import "@/components/form/ThemeSelect.scss";

import { ChangeEventHandler, ComponentProps, JSX } from "react";
import { ResumeThemeOptionItem, ResumeThemeType } from "@/types/resume";
import BaseSelect from "@/components/form/BaseSelect";
import { DefaultResumeThemeOptions } from "@/consts";
import classNames from "classnames";
import { isArray } from "@/utils";

type ThemeSelectProps = ComponentProps<"select"> & {
  hideSelect?: boolean;
  theme?: ResumeThemeType;
  themeOptions?: ResumeThemeOptionItem[];
  onThemeSelect?: (value: ResumeThemeType) => void;
  ["data-testid"]?: string;
  className?: string;
};

const ThemeSelect = ({
  hideSelect = true,
  theme,
  themeOptions = DefaultResumeThemeOptions,
  onThemeSelect,
  className,
  name = "theme-selector",
  title = "Choose which theme to view.",
  ...restProps
}: ThemeSelectProps): JSX.Element | null => {
  if (hideSelect) {
    return null;
  }

  //TODO: This will be replace by a MultiSelect UI

  const onThemeSelectChange: ChangeEventHandler<HTMLSelectElement> = (e) => {
    const selected = e.target.value as ResumeThemeType;
    return onThemeSelect && onThemeSelect(selected);
  };

  return (
    <>
      <div
        className={classNames("cp-resume-theme-selected", {
          "cp-resume-theme-selected-dark-text": theme === "full-wide",
        })}
        data-testid={`${restProps["data-testid"] ?? name}-selected`}
      >
        <strong>Selected Theme(s):</strong> <em>{isArray(theme) ? theme.join(", ") : theme}</em>
      </div>
      <BaseSelect
        {...restProps}
        name={name}
        className={classNames("cp-resume-theme-select", className, {
          "cp-resume-theme-select-dark-bg": theme === "full-wide" || theme?.includes("full-wide"),
        })}
        value={isArray(theme) ? theme[0] : theme}
        onChange={onThemeSelectChange}
        title={title}
        aria-label={restProps["aria-label"] ?? title}
      >
        {themeOptions.map((option) => {
          if (option.value === "side-scroll") {
            return null;
          }
          return (
            <option value={option.value} key={`${name}-option-${option.value}`}>
              {option.text}
            </option>
          );
        })}
      </BaseSelect>
    </>
  );
};

export default ThemeSelect;
