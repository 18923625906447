import "@/components/resume/locations/LocationLink.scss";
import { ComponentProps, JSX, useState } from "react";
import { IconSetType, ResumeMapApiType } from "@/types/resume";
import { isNilOrEmpty, isUndefined } from "@/utils";

import { DefaultIconSet } from "@/consts";
import IconWithText from "@/components/resume/icons/IconWithText";
import Link from "@/components/resume/links/Link";
import classNames from "classnames";
import { getMapUrl } from "@/utils";

type LocationProps = ComponentProps<"a"> & {
  locationQuery?: string;
  iconSet: IconSetType;
  iconPlacement?: "start" | "end" | "";
  mapApi?: ResumeMapApiType;
};

const LocationLink = ({
  locationQuery,
  iconSet = DefaultIconSet,
  iconPlacement = "start",
  mapApi,
  className,
  ...restProps
}: LocationProps): JSX.Element | null => {
  const [visited, setVisited] = useState(0);

  if (isNilOrEmpty(locationQuery) || isUndefined(locationQuery)) {
    return null;
  }

  const hideIcon = isNilOrEmpty(iconPlacement);

  return (
    <Link
      {...restProps}
      href={getMapUrl(locationQuery, mapApi)}
      target={"_blank"}
      className={classNames("cp-resume-location-link", className, {
        [`cp-resume-location-icon-${iconPlacement}`]: !!iconPlacement,
        "cp-resume-location-link-visited": visited > 0,
      })}
      linkText={
        hideIcon ? (
          locationQuery
        ) : (
          <IconWithText iconText="Location: " iconClassName={iconSet.location} text={locationQuery} />
        )
      }
      onClick={() => setVisited(visited + 1)}
    />
  );
};

export default LocationLink;
