import { ComponentProps, JSX } from "react";
import { isNil, stripProtocol } from "@/utils";

import DateTime from "@/components/resume/dates/DateTime";
import Link from "@/components/resume/links/Link";
import PipeItem from "@/components/resume/lists/pipes/PipeItem";
import Pipes from "@/components/resume/lists/pipes/Pipes";
import { PublicationItemType } from "@/types/resume";
import classNames from "classnames";
import { useResumeConfig } from "@/context/resume";

type PublicationItemProps = ComponentProps<"li"> & {
  publicationItem: PublicationItemType;
};

const PublicationItem = ({ publicationItem, className, ...restProps }: PublicationItemProps): JSX.Element | null => {
  const { months, pipes } = useResumeConfig();

  if (isNil(publicationItem) || publicationItem.hide) {
    return null;
  }

  return (
    <li {...restProps} className={classNames("cp-resume-publication-item", className)}>
      <Pipes className="cp-resume-publication-info" blockOnMobile={true}>
        <PipeItem itemValue={publicationItem.name} itemClassName="cp-resume-publication-name" itemType={pipes} />
        <PipeItem
          itemValue={<DateTime date={publicationItem.date} months={months} />}
          itemClassName="cp-resume-publication-date"
          itemType={pipes}
        />
        <PipeItem
          itemValue={publicationItem.publisher}
          itemClassName="cp-resume-publication-publisher"
          itemType={pipes}
        />
        <PipeItem itemValue={publicationItem.summary} itemClassName="cp-resume-publication-summary" itemType={pipes} />
        {publicationItem.url && (
          <PipeItem
            itemValue={
              <Link href={publicationItem.url} linkText={stripProtocol(publicationItem.url)} target="_blank" />
            }
            itemClassName="cp-resume-publication-url"
            itemType={pipes}
          />
        )}
      </Pipes>
    </li>
  );
};

export default PublicationItem;
