import { DefaultIconSet, DefaultMapApi, DefaultViewableSet } from "@/consts";
import { ReactNode, useEffect, useState } from "react";
import { ResumeConfigType, ResumeThemeType } from "@/types/resume";
import DateMonthStringsSet from "@/consts/DateMonthStringsSet";
import ResumeConfigContext from "@/context/resume/ResumeConfigContext";

const DefaultResumeConfig: ResumeConfigType = {
  theme: "base",
  iconSet: DefaultIconSet,
};

type ResumeConfigProviderProps = {
  children: ReactNode;
  config?: ResumeConfigType;
  themeOverride?: ResumeThemeType;
};

export const ResumeConfigProvider = ({
  children,
  config = DefaultResumeConfig,
  themeOverride,
}: ResumeConfigProviderProps) => {
  const [resumeConfig, setResumeConfig] = useState(config);

  useEffect(() => {
    config && setResumeConfig(config);
  }, [config]);

  const theme = themeOverride ?? resumeConfig?.theme ?? "base";
  const viewable = resumeConfig?.viewable ?? DefaultViewableSet;
  const iconSet = Object.assign({}, DefaultIconSet, resumeConfig?.iconSet);
  const titles = resumeConfig?.titles;
  const mapApi = resumeConfig?.mapApi ?? DefaultMapApi;
  const months = resumeConfig?.months ?? DateMonthStringsSet;
  const pipes = resumeConfig?.pipes ?? "pipe";

  return (
    <ResumeConfigContext.Provider
      value={{
        resumeConfig,
        iconSet,
        theme,
        viewable,
        titles,
        mapApi,
        months,
        pipes,
      }}
    >
      {children}
    </ResumeConfigContext.Provider>
  );
};

export default ResumeConfigProvider;
