import { ChangeEventHandler, ComponentProps, JSX } from "react";
import BaseSelect from "@/components/form/BaseSelect";
import { ResumeThemeType } from "@/types/resume";
import { buildDarkModeSelectOptions } from "@/utils";
import classNames from "classnames";

type DarkModeSelectProps = ComponentProps<"select"> & {
  hideSelect?: boolean;
  onDarkModeSelect?: (value: ResumeThemeType) => void;
  className?: string;
};

const DarkModeSelect = ({
  hideSelect = true,
  onDarkModeSelect,
  className,
  name = "dark-mode-selector",
  title = "Choose which color mode to view: Light, Dark, or Auto?",
  ...restProps
}: DarkModeSelectProps): JSX.Element | null => {
  if (hideSelect) {
    return null;
  }

  const darkModeSelectOptions = buildDarkModeSelectOptions({
    noOptions: hideSelect,
  });

  const onDarkModeSelectChange: ChangeEventHandler<HTMLSelectElement> = (e) => {
    const selected = e.target.value as ResumeThemeType;

    return onDarkModeSelect && onDarkModeSelect(selected);
  };

  if (darkModeSelectOptions.length < 2) {
    return null;
  }

  return (
    <BaseSelect
      {...restProps}
      name={name}
      className={classNames("cp-resume-dark-mode-select", className)}
      onChange={onDarkModeSelectChange}
      title={title}
      aria-label={restProps["aria-label"] ?? title}
    >
      {darkModeSelectOptions.map((option) => {
        return (
          <option value={option.value} key={`${name}-option-${option.value}`}>
            {option.text}
          </option>
        );
      })}
    </BaseSelect>
  );
};

export default DarkModeSelect;
