import { ResumeViewableType } from "@/types/resume";

export const DefaultCompleteViewableSet: ResumeViewableType[] = [
  "basics",
  "basics.name",
  "basics.image",
  "basics.label",
  "basics.url",
  "basics.summary",
  "basics.location",
  "basics.email",
  "basics.phone",
  "basics.profiles",
  "skills",
  "work",
  "work.keywords",
  "education",
  "education.dates",
  "education.courses",
  "education.score",
  "volunteer",
  "awards",
  "certificates",
  "publications",
  "languages",
  "interests",
  "references",
  "projects",
  "project.description",
  "project.highlights",
];

const DefaultViewableSet: ResumeViewableType[] = [
  "basics",
  "basics.name",
  "basics.label",
  "basics.url",
  "basics.summary",
  "basics.email",
  "basics.profiles",
  "skills",
  "work",
  "education",
];

export default DefaultViewableSet;
