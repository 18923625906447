import { ComponentProps, JSX } from "react";
import { checkKeyViewable, isArray, isNil } from "@/utils";

import DateRange from "@/components/resume/dates/DateRange";
import HighlightsList from "@/components/resume/lists/highlights/HighlightsList";
import Link from "@/components/resume/links/Link";
import LocationLink from "@/components/resume/locations/LocationLink";
import PipeItem from "@/components/resume/lists/pipes/PipeItem";
import Pipes from "@/components/resume/lists/pipes/Pipes";
import { WorkItemType } from "@/types/resume";
import classNames from "classnames";
import { useResumeConfig } from "@/context/resume";

type WorkItemProps = ComponentProps<"li"> & {
  workItem: WorkItemType;
  keywordsHeading?: string;
};

const WorkItem = ({
  workItem,
  className,
  keywordsHeading = "Keywords: ",
  ...restProps
}: WorkItemProps): JSX.Element | null => {
  const { iconSet, mapApi, months, pipes, viewable } = useResumeConfig();
  if (isNil(workItem) || workItem.hide) {
    return null;
  }

  const hasKeywords =
    checkKeyViewable("work.keywords", viewable) && isArray(workItem.keywords) && workItem.keywords.length > 0;

  return (
    <li {...restProps} className={classNames("cp-resume-work-item", className)}>
      <Pipes className="cp-resume-work-info" blockOnMobile={true}>
        <PipeItem itemValue={workItem.position} itemClassName="cp-resume-work-position" itemType={pipes} />
        <PipeItem
          itemValue={
            <DateRange
              startDate={workItem.startDate}
              endDate={workItem.endDate}
              months={months}
              className="cp-resume-work-dates"
            />
          }
          itemClassName="cp-resume-work-dates"
          itemType={pipes}
        />
        <PipeItem
          itemValue={
            workItem.url ? <Link href={workItem.url} linkText={workItem.name} target="_blank" /> : workItem.name
          }
          itemClassName="cp-resume-work-name"
          itemType={pipes}
        />
        <PipeItem itemValue={workItem.summary} itemClassName="cp-resume-work-summary" itemType={pipes} />
        <PipeItem
          itemValue={
            <LocationLink iconSet={iconSet} locationQuery={workItem.location} mapApi={mapApi} iconPlacement={"end"} />
          }
          itemClassName="cp-resume-work-location"
          itemType={pipes}
        />
      </Pipes>
      <HighlightsList highlights={workItem.highlights} className="cp-resume-work-highlights" />
      {hasKeywords && (
        <div className="cp-resume-work-keywords">
          <strong className="cp-resume-work-keywords-heading">{keywordsHeading}</strong>
          <Pipes className="cp-resume-work-keywords-list">
            {workItem.keywords?.map((keyword, index) => {
              return (
                <PipeItem
                  itemValue={keyword}
                  itemClassName="cp-resume-work-keyword"
                  itemType={pipes}
                  key={`keyword-${keyword.replace(" ", "_")}-${index}`}
                />
              );
            })}
          </Pipes>
        </div>
      )}
    </li>
  );
};

export default WorkItem;
