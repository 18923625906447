import { JSX } from "react";
import { SkillType } from "@/types/resume";
import { isNilOrEmpty } from "@/utils";

type SkillItemProps = {
  skill?: SkillType;
  asTable?: boolean;
  dataTestId?: string;
};

const SkillItem = ({ skill, asTable = true, dataTestId }: SkillItemProps): JSX.Element | null => {
  if (isNilOrEmpty(skill) || !skill?.name || skill.hide) {
    return null;
  }

  const SkillItemEl = asTable ? "td" : "li";

  const skillTitle = `Skill: ${skill.name}`;
  const levelTitle = `Level: ${skill.level}`;
  const keywordsText = skill.keywords ? skill.keywords.join(", ") : undefined;

  return (
    <SkillItemEl className="cp-resume-skill" aria-label={`${skillTitle}, ${levelTitle}`} data-testid={dataTestId}>
      <div className="cp-resume-skill-name" title={skillTitle}>
        {skill.name}
      </div>
      <div className="cp-resume-skill-level" title={levelTitle}>
        {skill.level}
      </div>

      {keywordsText && (
        <div className="cp-resume-skill-keywords" title={`Keywords: ${keywordsText}`}>
          {keywordsText}
        </div>
      )}
    </SkillItemEl>
  );
};

export default SkillItem;
