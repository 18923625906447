import { isNilOrEmpty, isObject } from "@/utils";

import { JSX } from "react";
import { LinkProps } from "@/components/resume/links/Link";
import ObscureLink from "@/components/resume/links/ObscureLink";
import classNames from "classnames";

type JsonDownloadLinkProps = Omit<LinkProps, "href"> & {
  fileName?: string;
  iconClassName?: string;
  json?: unknown;
};

const JSONDownloadLink = ({
  className,
  iconClassName,
  fileName = "file",
  json,
  ...restProps
}: JsonDownloadLinkProps): JSX.Element | null => {
  if (!fileName || isNilOrEmpty(fileName) || !isObject(json)) {
    return null;
  }
  const jsonDownloadLinkHref = `data:text/json;charset=utf-8,${encodeURIComponent(JSON.stringify(json))}`;
  const fullFileName = `${fileName}.json`;

  return (
    <ObscureLink
      {...restProps}
      className={classNames("cp-resume-json-download-link", className)}
      href={jsonDownloadLinkHref}
      linkText={
        <>
          <i className={iconClassName} aria-label={""} />
          <span className="sr-only">Download {fullFileName}</span>
        </>
      }
      download={fullFileName}
      title={`Download ${fullFileName}`}
    />
  );
};

export default JSONDownloadLink;
