import { IconSetType, ResumePDFUrl } from "@/types/resume";
import { ComponentProps } from "react";
import classNames from "classnames";
import { isNilOrEmpty } from "@/utils";

type ToolbarOptionPDFLinkProps = ComponentProps<"a"> & {
  iconSet: IconSetType;
  resumePDFUrl?: ResumePDFUrl;
  srOnlyText?: string;
};

const ToolbarOptionPDFLink = ({
  iconSet,
  resumePDFUrl,
  title = "Click to view Resume PDF",
  srOnlyText = "View PDF",
  rel = "noopener noreferrer",
  target = "_blank",
  className,
  ...restProps
}: ToolbarOptionPDFLinkProps) => {
  if (isNilOrEmpty(resumePDFUrl)) {
    return null;
  }

  return (
    <a
      {...restProps}
      href={resumePDFUrl}
      title={title}
      target={target}
      rel={rel}
      className={classNames("cp-resume-toolbar-option cp-resume-pdf", className)}
    >
      <i className={classNames("cp-resume-toolbar-option-icon", iconSet.filePdf)} />
      <span className="sr-only">{srOnlyText}</span>
    </a>
  );
};

export default ToolbarOptionPDFLink;
