import "@/components/resume/lists/education/EducationItem.scss";
import { ComponentProps, JSX } from "react";
import { checkKeyViewable, isArray, isNilOrEmpty, stripProtocol } from "@/utils";

import DateRange from "@/components/resume/dates/DateRange";
import { EducationItemType } from "@/types/resume";
import Link from "@/components/resume/links/Link";
import LocationLink from "@/components/resume/locations/LocationLink";
import PipeItem from "@/components/resume/lists/pipes/PipeItem";
import Pipes from "@/components/resume/lists/pipes/Pipes";
import classNames from "classnames";
import { useResumeConfig } from "@/context/resume";

type EducationItemProps = ComponentProps<"li"> & {
  education: EducationItemType;
};

const EducationItem = ({ education, className, ...restProps }: EducationItemProps): JSX.Element | null => {
  const { iconSet, mapApi, months, pipes, viewable } = useResumeConfig();

  if (isNilOrEmpty(education) || education.hide) {
    return null;
  }

  const showCourses = checkKeyViewable("education.courses", viewable);
  const showDates = checkKeyViewable("education.dates", viewable);
  const showScore = checkKeyViewable("education.score", viewable);

  return (
    <li {...restProps} className={classNames("cp-resume-education-item", className)}>
      <Pipes className="cp-resume-education-info" blockOnMobile={true}>
        <>
          <PipeItem itemValue={education.studyType} itemClassName="cp-resume-education-study-type" itemType={pipes} />
          <PipeItem itemValue={education.area} itemClassName="cp-resume-education-description" itemType={pipes} />
        </>

        {showDates && (education.startDate || education.endDate) && (
          <PipeItem
            itemValue={
              <DateRange
                startDate={education.startDate}
                endDate={education.endDate}
                className="cp-resume-education-dates"
                months={months}
              />
            }
            itemClassName="cp-resume-education-dates"
            itemType={pipes}
          />
        )}
        <PipeItem itemValue={education.institution} itemClassName="cp-resume-education-institution" itemType={pipes} />

        {education.url && (
          <PipeItem
            itemValue={<Link href={education.url} linkText={stripProtocol(education.url)} target="_blank" />}
            itemClassName="cp-resume-education-url"
            itemType={pipes}
          />
        )}

        {showScore && education.score && (
          <PipeItem
            itemValue={<>Score: {education.score}</>}
            itemClassName="cp-resume-education-score"
            itemType={pipes}
          />
        )}
        <PipeItem
          itemValue={
            <LocationLink iconSet={iconSet} locationQuery={education.location} iconPlacement={"end"} mapApi={mapApi} />
          }
          itemClassName="cp-resume-education-location"
          itemType={pipes}
        />
      </Pipes>
      {showCourses && isArray(education.courses) && education.courses.length > 0 && (
        <div className="cp-resume-education-courses">
          <strong className="cp-resume-education-courses-heading">Courses:</strong>{" "}
          <span className="cp-resume-education-courses-list">{education.courses.join(", ")}</span>
        </div>
      )}
    </li>
  );
};

export default EducationItem;
